import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import requests from "./requests";
import { Grid } from "@mui/material";
import { Pagination } from "antd";
import axios from "./axios";
import Loader from "./loading/Loader";
import LazyLoad from "react-lazyload";

function MovieGroup({ isLargeRow = false }) {
  let { moviegroup } = useParams();
  const [data, setData] = useState();
  const [movie, setMovie] = useState([]);
  const base_url = "http://image.tmdb.org/t/p/original/";
  const [pages, setPage] = useState(1);
  const handleChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const request = await axios.get(requests(pages)[`${moviegroup}`]);
      setLoading(false);
      setMovie(request.data.results);
      setData(request);

      return request;
    }

    fetchData();
  }, [moviegroup, pages]);

  function title() {
    switch (moviegroup) {
      case "top-rated":
        return "Top Rated";

      case "get-popular":
        return "Phổ biến";
      case "now-playing":
        return "Đang chiếu";
      case "up-coming":
        return "Sắp chiếu";
      default:
        navigate("*");
    }
  }
  return (
    <div style={{ height: "fit-content", backgroundColor: "#111" }}>
      <h1 style={{ color: "#f26b38", textAlign: "center", margin: "1rem" }}>
        Phim {title()}
      </h1>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={2} className="row_posters">
            {movie?.map(
              (movie) =>
                ((isLargeRow && movie.poster_path) ||
                  (!isLargeRow && movie.backdrop_path)) && (
                  <Grid xs={4} className="item_poster">
                    <Link to={`/movie-detail/${movie.id}`}>
                      <LazyLoad
                        height={300}
                        className="wow animate__animated animate__zoomIn"
                      >
                        <img
                          className={`row_poster ${
                            isLargeRow && "row_posterLarge"
                          }`}
                          key={movie.id}
                          src={`${base_url}${
                            isLargeRow ? movie.poster_path : movie.backdrop_path
                          }`}
                          alt={movie.name}
                        />
                        <h1
                          style={{
                            color: "orange",
                            display: "block",
                            fontSize: "1.5rem",
                            fontWeight: 400,
                            transition: "transform 450ms",
                          }}
                        >
                          {movie.title}
                        </h1>
                      </LazyLoad>
                    </Link>
                  </Grid>
                )
            )}
          </Grid>
        </>
      )}
      <div className="pagin-container">
        <Pagination
          className="pagin-content"
          defaultCurrent={1}
          total={data?.data.total_pages}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default MovieGroup;
