import React from "react";
import "./loader.css";

function Loader() {
  return (
    <div className="loadingio-spinner-bean-eater-0h912fwkly7c">
      <div className="ldio-z4ys4525dds">
        <div>
          <div />
          <div />
          <div />
        </div>
        <div>
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
}

export default Loader;
